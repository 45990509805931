<template>
  <div class="coupon-exchange-setting">
    <PageTitle title="通知對象範本" btn="新增" @btnClick="onCreate" />
    <FiltersContainer>
      <SearchTextInput :value.sync="search.name" placeholder="輸入範本名稱" @refresh="refresh(true)" />
      <el-select v-model="search.targetType" placeholder="選擇通知對象類型" clearable @change="refresh(true)" @clear="refresh(true)">
        <el-option
          v-for="option in targetTypeConfig"
          :key="option.value"
          :value="option.value"
          :label="option.label"
        />
      </el-select>
    </FiltersContainer>
    <NotificationTargetTemplateTable v-loading="loading.table" :tableData="tableData" @refresh="refresh(false)" />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive, onActivated } from 'vue'
import { useRouter } from 'vue-router/composables'
import { useTable } from '@/use/table'
import { GetProgressNotificationTargetTemplate, GetProgressNotificationTargetTemplateCount } from '@/api/progressNotification'
import PageTitle from '@/components/Title/PageTitle.vue'
import NotificationTargetTemplateTable from './components/NotificationTargetTemplateTable.vue'
import SearchTextInput from '@/components/Input/SearchTextInput.vue'
import { targetTypeConfig } from '@/config/progressNotification'

export default defineComponent({
  name: 'NotificationTargetTemplateList',
  components: {
    PageTitle,
    NotificationTargetTemplateTable,
    SearchTextInput,
  },
  setup (props) {
    const router = useRouter()
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
      fetchData,
      fetchDataCount,
    } = useTable()

    const search = reactive({
      name: null,
      targetType: null,
    })

    const onCreate = () => {
      router.push({ name: 'ProgressNotificationTargetTemplateEdit' })
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        name: search.name || undefined,
        targetType: search.targetType || undefined,
      }
      await Promise.allSettled([
        fetchData(GetProgressNotificationTargetTemplate, payload),
        fetchDataCount(GetProgressNotificationTargetTemplateCount, payload),
      ])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(() => {
      refresh()
    })
    onActivated(() => {
      refresh()
    })
    return {
      loading,
      tableData,
      tableOptions,
      tableDataCount,
      onCreate,
      refresh,
      search,
      targetTypeConfig,
    }
  },
})
</script>
