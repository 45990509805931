<template>
  <div class="data-table">
    <el-table :data="displayData">
      <EmptyBlock slot="empty" />
      <el-table-column label="範本名稱" prop="name" align="center" />
      <el-table-column label="通知對象類型" prop="type" align="center" />
      <el-table-column label="通知對象" prop="targets" align="center" />
      <el-table-column label="是否有通知選用" align="center">
        <template slot-scope="scope">
          <Tag :type="scope.row.hasBindNotifySettingTarget ? 'action' : 'info'">{{ scope.row.hasBindNotifySettingTarget ? '是' : '否' }}</Tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            @edit="onRowEdit(scope.row)"
            @delete="onRowDelete(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>

    <DeleteDialog
      v-if="modal.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="modal.delete = false"
      @delete="deleteRow"
    />
    <DeleteDialog
      v-if="modal.deleteSelected"
      title="有通知選擇此範本作為通知對象"
      content="刪除後，選取此範本的通知將改為關閉。範本內包含的通知對象，可能收不到通知、或改為接收預設的通知內容。確定要刪除？"
      width="40%"
      @close="modal.deleteSelected = false"
      @delete="deleteRow"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, reactive, onMounted } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { DeleteProgressNotificationTargetTemplate, GetNotifyTargetTemplateAppointmentConfigOptions } from '@/api/progressNotification'
import { useTable } from '@/use/table'
import { get, map, flatten, find, isEmpty } from 'lodash'
import { useRouter } from 'vue-router/composables'
import { targetTypeConfig } from '@/config/progressNotification'
import { useFetch } from '@/use/fetch'
import { useProgressNotificationTarget } from '@/use/useProgressNotificationTarget'

export default defineComponent({
  name: 'NotificationTargetTemplateTable',
  components: { EmptyBlock },
  props: {
    tableData: { type: Array, defalt: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const router = useRouter()
    const { simpleFetch } = useFetch()
    const { shopId, dateFormat } = useTable()
    const reservationServicesData = ref({})
    const selectRow = ref(null)
    const { categoryServiceDataList, serviceDataList, attachServiceDataList, getDataByPath } = useProgressNotificationTarget({ reservationServicesData: reservationServicesData })
    const modal = reactive({
      delete: false,
      deleteSelected: false,
    })

    const displayData = computed(() => {
      const data = props.tableData
      // handle data here
      return map(data, (i) => {
        const targets = syncTarget(i.appointmentConfig)
        return {
          id: i.id,
          name: i.name,
          type: get(targetTypeConfig[i.type], 'label'),
          targets,
          hasBindNotifySettingTarget: i.hasBindNotifySettingTarget,
        }
      })
    })

    const syncTarget = (config) => {
      const serviceSettings = get(config, 'serviceSettings')
      const subServiceSettings = get(config, 'subServiceSettings')
      const serviceAttachSettings = get(config, 'serviceAttachSettings')

      // if (!isEmpty(categoryServiceDataList.value) && !isEmpty(serviceDataList.value) && !isEmpty(attachServiceDataList.value)) {
      const includeServices = map(get(serviceSettings, 'includeServiceIds'), serviceId => {
        return find(getDataByPath(categoryServiceDataList.value, 'services'), { id: serviceId }) || find(serviceDataList.value, { id: serviceId })
      }).filter(Boolean)
      const category = map(get(serviceSettings, 'categoryIds'), serviceId => {
        return find(categoryServiceDataList.value, { id: serviceId })
      }).filter(Boolean)
      const subServices = map(get(subServiceSettings, 'subServiceIds'), serviceId => {
        return find(getDataByPath(serviceDataList.value, 'subServices'), { id: serviceId })
      }).filter(Boolean)
      const attachServices = map(get(serviceAttachSettings, 'serviceAttachIds'), serviceId => {
        return find(attachServiceDataList.value, { id: serviceId })
      }).filter(Boolean)

      const result = [...includeServices, ...category, ...subServices, ...attachServices]
      return map(result, item => item?.name).join('、')
      // }
    }

    const onRowEdit = (row) => {
      router.push({
        name: 'ProgressNotificationTargetTemplateEdit',
        params: {
          shopId: shopId.value,
          id: row.id,
        },
      })
    }

    const onRowDelete = (row) => {
      selectRow.value = row
      if (row.hasBindNotifySettingTarget) modal.deleteSelected = true
      else modal.delete = true
    }

    const deleteRow = async () => {
      const [, err] = await DeleteProgressNotificationTargetTemplate({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      if (err) return window.$message.error(err)
      window.$message.success('刪除成功！')
      modal.delete = false
      modal.deleteSelected = false
      emit('refresh')
    }
    onMounted(async () => {
      await simpleFetch(GetNotifyTargetTemplateAppointmentConfigOptions, { shopId: shopId.value }, (res) => {
        reservationServicesData.value = res
      })
    })
    return {
      modal,
      displayData,
      onRowEdit,
      onRowDelete,
      deleteRow,
    }
  },
})
</script>
